import "slick-carousel";

$(function(){
  $('.slick').on('init', function (event, slick, currentSlide, nextSlide) {
    var slideItem = $(this).find(".slick-slide");
    for(var i=0; i < slideItem.length; i++){
      var slideImg = slideItem.filter(function(){return($(this).data('slick-index') === i)}).find("img").clone();
      var src = slideImg.attr('src');
      var dot = $(this).find(".slick-dots").find("li").eq(i).find("button");
      dot.css("background", `url(${src}) no-repeat center center`);
      dot.css("background-size", "contain");
    }
  });

  $('.slick').slick({
    dots: true,
  });
});
